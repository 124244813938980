
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function verifyFitmentRT () {
    function repeatSelects1(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3594'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3796'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled,
                    'aria-label': this.title
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', 'Select ', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7871'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7874'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ 'Select ' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'aria-label': this.title,
                        'key': '2883'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', 'Select ', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    function repeatSelects2(selects, selectsIndex) {
        return [selects(function () {
                function repeatEntry1(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry2(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry3(entry, entryIndex) {
                    return _createElement('option', {
                        'disabled': this.loading,
                        'value': entry.term,
                        'key': entry.value
                    }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                }
                function repeatEntry4(entry, index) {
                    return _createElement('div', {
                        'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                        'key': entry.value,
                        'onClick': () => this.onChange(entry.term)
                    }, this.showCheckboxes ? _createElement('input', {
                        'type': 'checkbox',
                        'readOnly': true,
                        'checked': entry.selected,
                        'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                        'key': '3594'
                    }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                        'className': 'cm_dropdown_hitCount',
                        'key': '3796'
                    }, entry.hitCount) : null);
                }
                return _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                    'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                    'key': '39'
                }, _createElement('select', {
                    'className': 'cm_select_inner-select cm_select__pretty',
                    'onChange': this.onChange,
                    'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                    'disabled': this.disabled,
                    'aria-label': this.title
                }, this.useNativeDropdown ? [
                    !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                        'value': '',
                        'key': 'null-option'
                    }, '\n          ', 'Select ', this.title, '\n        ') : null,
                    this.loading ? _createElement('option', {
                        'key': 'loading-option',
                        'disabled': true
                    }, '...loading...') : null,
                    this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7871'
                        }, 'Popular Makes'),
                        _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                        _createElement('option', {
                            'className': 'cm_option_title',
                            'disabled': true,
                            'key': '7874'
                        }, 'All Makes'),
                        _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                    ] : null,
                    this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                ] : null, !this.useNativeDropdown ? _createElement('option', {
                    'key': '_current',
                    'value': '_current'
                }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ 'Select ' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                            return _createElement('div', { 'className': 'cm_filterInput' });
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                    'div',
                    {
                        'className': 'cm_dropdown cm_select_dropdown cm_hide',
                        'aria-label': this.title,
                        'key': '2883'
                    },
                    !this.hideNullOption ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'onClick': () => this.onChange('')
                    }, '\n      ', 'Select ', this.title, '\n    ') : null,
                    this.loading ? _createElement('div', {
                        'className': 'option',
                        'key': 'null-option',
                        'disabled': true
                    }, '...loading...') : null,
                    !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                ]) : null);
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_vehicle-widget cm_vehicle-widget__verify-fitment' }, this.template === 'active' ? _createElement('div', {
        'className': 'cm_vehicle-widget_dropdowns cmTemplate_active',
        'key': '68'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, window.Convermax.config?.verifyFitmentTitle || 'Verify fitment with your vehicle'), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects1.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__go button button--primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    ', window.document.body.classList.contains('template-product') ? 'Verify fitment' : 'GO', '\n  '), _createElement('div', {
        'className': 'cm_vehicle-widget_button cm_vehicle-widget_button__clear button button--secondary',
        'onClick': this.discardVehicle,
        'disabled': !this.allowToDiscardVehicle,
        'role': 'button',
        'tabIndex': this.allowToDiscardVehicle ? '0' : '-1'
    }, [_createElement('svg', {
            'width': '24',
            'height': '24',
            'viewBox': '0 0 24 24',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '69320'
        }, _createElement('g', { 'id': 'restart_alt' }, _createElement('mask', {
            'id': 'mask0_1239_10799',
            'style': { maskType: 'alpha' },
            'maskUnits': 'userSpaceOnUse',
            'x': '0',
            'y': '0',
            'width': '24',
            'height': '24'
        }, _createElement('rect', {
            'id': 'Bounding box',
            'width': '24',
            'height': '24',
            'fill': '#D9D9D9'
        })), _createElement('g', { 'mask': 'url(#mask0_1239_10799)' }, _createElement('path', {
            'id': 'restart_alt_2',
            'd': 'M11 20.4307C9.12434 20.1871 7.57211 19.3609 6.34327 17.9519C5.11442 16.5429 4.5 14.8923 4.5 13C4.5 11.9833 4.70064 11.0093 5.10192 10.0779C5.50321 9.14649 6.06538 8.32822 6.78845 7.62309L7.85765 8.69232C7.24355 9.25257 6.78042 9.90288 6.46825 10.6433C6.15607 11.3836 5.99997 12.1692 5.99997 13C5.99997 14.4667 6.46824 15.7593 7.40477 16.8779C8.34132 17.9965 9.53972 18.6808 11 18.9308V20.4307ZM13 20.45V18.95C14.4436 18.6577 15.6378 17.958 16.5827 16.8509C17.5275 15.7439 18 14.4602 18 13C18 11.3333 17.4166 9.91666 16.25 8.74999C15.0833 7.58332 13.6666 6.99999 12 6.99999H11.6461L12.9961 8.35002L11.9423 9.40384L8.78848 6.25002L11.9423 3.09619L12.9961 4.15002L11.6461 5.50002H12C14.0923 5.50002 15.8653 6.22694 17.3192 7.68079C18.773 9.13462 19.5 10.9077 19.5 13C19.5 14.882 18.8839 16.5243 17.6519 17.9269C16.4198 19.3294 14.8692 20.1705 13 20.45Z',
            'fill': 'white'
        }))))], _createElement('span', {}, 'Reset'))))) : null, this.template === 'locked' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__' + this.fits + ' cmTemplate_locked',
        'key': '8293'
    }, _createElement('div', { 'className': 'cm_verify-fitment_fitment__container' }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'width': '17',
            'height': '16',
            'viewBox': '0 0 17 16',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '85450'
        }, _createElement('path', {
            'd': 'M7.14299 1.19198C7.9192 0.51017 9.0808 0.510169 9.85701 1.19198C10.2816 1.56497 10.841 1.74673 11.4038 1.69457C12.4325 1.59921 13.3723 2.28199 13.5995 3.28983C13.7238 3.84116 14.0695 4.31703 14.5555 4.60561C15.4438 5.13314 15.8027 6.23789 15.3941 7.1868C15.1706 7.7059 15.1706 8.2941 15.3941 8.8132C15.8027 9.76211 15.4438 10.8669 14.5555 11.3944C14.0695 11.683 13.7238 12.1588 13.5995 12.7102C13.3723 13.718 12.4325 14.4008 11.4038 14.3054C10.841 14.2533 10.2816 14.435 9.85701 14.808C9.0808 15.4898 7.9192 15.4898 7.14299 14.808C6.71837 14.435 6.15895 14.2533 5.59619 14.3054C4.56747 14.4008 3.62771 13.718 3.4005 12.7102C3.27621 12.1588 2.93047 11.683 2.44453 11.3944C1.55623 10.8669 1.19727 9.76211 1.60585 8.8132C1.82936 8.2941 1.82936 7.7059 1.60585 7.1868C1.19727 6.23789 1.55623 5.13314 2.44453 4.60561C2.93047 4.31703 3.27621 3.84116 3.4005 3.28983C3.62771 2.28199 4.56747 1.59921 5.59619 1.69457C6.15895 1.74673 6.71837 1.56497 7.14299 1.19198Z',
            'fill': '#EA0029'
        }), _createElement('path', {
            'd': 'M7.01462 9.52793L5.34624 7.76271L5.23723 7.64737L5.12821 7.76271L4.53575 8.38956L4.43836 8.49259L4.53575 8.59563L6.90561 11.103L7.01462 11.2184L7.12364 11.103L12.2019 5.73001L12.2993 5.62698L12.2019 5.52394L11.6094 4.89709L11.5004 4.78175L11.3914 4.89709L7.01462 9.52793Z',
            'fill': 'white',
            'stroke': 'white',
            'strokeWidth': '0.3'
        }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-times',
            'height': '20px',
            'role': 'img',
            'viewBox': '0 0 22 22',
            'key': '100710'
        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '102610'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null, this.fits === 'aggressive' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-exclamation',
            'height': '20',
            'viewBox': '0 0 256 255',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '107690'
        }, _createElement('path', {
            'fillRule': 'evenodd',
            'clipRule': 'evenodd',
            'd': 'M112.098 0.528293C105.702 1.30529 89.9175 5.21029 83.6615 7.56329C64.7805 14.6653 49.2545 25.1013 34.5985 40.5423C-0.248547 77.2563 -9.69655 131.035 10.4785 177.833C17.3025 193.66 23.8655 203.124 37.5985 216.939C51.1805 230.602 61.9924 238.186 77.0984 244.649C104.915 256.549 138.414 258.074 166.974 248.741C177.998 245.139 194.9 236.465 203.598 229.946C212.511 223.266 228.177 206.84 233.748 198.332C262.341 154.67 262.457 100.262 234.048 56.8423C227.749 47.2143 210.42 29.3733 201.129 22.9513C185.936 12.4473 166.593 4.56829 148.217 1.39729C140.191 0.0112929 120.292 -0.466707 112.098 0.528293ZM146.598 101.364V157.864H127.598H108.598V101.364V44.8643H127.598H146.598V101.364ZM136.699 179.648C141.014 181.902 142.56 183.448 144.814 187.763C148.264 194.367 148.343 198.988 145.115 205.418C138.666 218.265 122.327 220.292 112.789 209.428C104.371 199.842 107.303 185.18 118.78 179.461C125.482 176.121 130.038 176.169 136.699 179.648Z'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, this.fits === 'yes' ? ['Fits my vehicle'] : null, this.fits === 'no' ? ['Doesn\'t fit my vehicle'] : null, this.fits === 'aggressive' ? ['This product may require modifications to fit your'] : null), _createElement('div', { 'className': 'cm_verify-fitment_body' }, _createElement('span', { 'className': 'cm_verify-fitment_vehicle' }, this.vehicleString || 'Vehicle'), _createElement('a', {
        'className': 'cm_verify-fitment_change',
        'onClick': this.changeVehicle
    }, 'Change vehicle')), this.fits === 'no' ? _createElement('div', {
        'className': 'cm_verify-fitment_compatible-parts-container',
        'key': '12652'
    }, _createElement('a', {
        'href': this.getVehicleUrl() + '/Fitment/Vehicle-Specific',
        'className': 'cm_verify-fitment_compatible-parts'
    }, '\n            Click here for products that do fit your vehicle\n          ')) : null))) : null, this.template === 'inexact' ? _createElement('div', {
        'className': 'cm_vehicle-widget_specifier cmTemplate_inexact',
        'key': '13003'
    }, _createElement('div', { 'className': 'cm_vehicle-widget_title' }, 'Please specify ', this.selectFields.join(', '), ' for your ', this.vehicleString), _createElement('div', { 'className': 'cm_vehicle-widget_body' }, _createElement.apply(this, [
        'div',
        { 'className': 'cm_vehicle-widget_select-container  cmRepeater_selects' },
        _map(this.selects, repeatSelects2.bind(this))
    ]), _createElement('div', { 'className': 'cm_vehicle-widget_button-container' }, _createElement('div', {
        'className': 'cm_vehicle-widget_button button button--primary button button--primary',
        'onClick': this.selectVehicle,
        'disabled': !this.allowToSetVehicle,
        'role': 'button',
        'tabIndex': this.allowToSetVehicle ? '0' : '-1'
    }, '\n    ', window.document.body.classList.contains('template-product') ? 'Verify fitment' : 'GO', '\n  ')))) : null, this.template === 'universal' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__universal cmTemplate_universal',
        'key': '19617'
    }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'width': '17',
            'height': '16',
            'viewBox': '0 0 17 16',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '198160'
        }, _createElement('path', {
            'd': 'M7.14299 1.19198C7.9192 0.51017 9.0808 0.510169 9.85701 1.19198C10.2816 1.56497 10.841 1.74673 11.4038 1.69457C12.4325 1.59921 13.3723 2.28199 13.5995 3.28983C13.7238 3.84116 14.0695 4.31703 14.5555 4.60561C15.4438 5.13314 15.8027 6.23789 15.3941 7.1868C15.1706 7.7059 15.1706 8.2941 15.3941 8.8132C15.8027 9.76211 15.4438 10.8669 14.5555 11.3944C14.0695 11.683 13.7238 12.1588 13.5995 12.7102C13.3723 13.718 12.4325 14.4008 11.4038 14.3054C10.841 14.2533 10.2816 14.435 9.85701 14.808C9.0808 15.4898 7.9192 15.4898 7.14299 14.808C6.71837 14.435 6.15895 14.2533 5.59619 14.3054C4.56747 14.4008 3.62771 13.718 3.4005 12.7102C3.27621 12.1588 2.93047 11.683 2.44453 11.3944C1.55623 10.8669 1.19727 9.76211 1.60585 8.8132C1.82936 8.2941 1.82936 7.7059 1.60585 7.1868C1.19727 6.23789 1.55623 5.13314 2.44453 4.60561C2.93047 4.31703 3.27621 3.84116 3.4005 3.28983C3.62771 2.28199 4.56747 1.59921 5.59619 1.69457C6.15895 1.74673 6.71837 1.56497 7.14299 1.19198Z',
            'fill': '#EA0029'
        }), _createElement('path', {
            'd': 'M7.01462 9.52793L5.34624 7.76271L5.23723 7.64737L5.12821 7.76271L4.53575 8.38956L4.43836 8.49259L4.53575 8.59563L6.90561 11.103L7.01462 11.2184L7.12364 11.103L12.2019 5.73001L12.2993 5.62698L12.2019 5.52394L11.6094 4.89709L11.5004 4.78175L11.3914 4.89709L7.01462 9.52793Z',
            'fill': 'white',
            'stroke': 'white',
            'strokeWidth': '0.3'
        }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-times',
            'height': '20px',
            'role': 'img',
            'viewBox': '0 0 22 22',
            'key': '213420'
        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '215320'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null, this.fits === 'aggressive' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-exclamation',
            'height': '20',
            'viewBox': '0 0 256 255',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '220400'
        }, _createElement('path', {
            'fillRule': 'evenodd',
            'clipRule': 'evenodd',
            'd': 'M112.098 0.528293C105.702 1.30529 89.9175 5.21029 83.6615 7.56329C64.7805 14.6653 49.2545 25.1013 34.5985 40.5423C-0.248547 77.2563 -9.69655 131.035 10.4785 177.833C17.3025 193.66 23.8655 203.124 37.5985 216.939C51.1805 230.602 61.9924 238.186 77.0984 244.649C104.915 256.549 138.414 258.074 166.974 248.741C177.998 245.139 194.9 236.465 203.598 229.946C212.511 223.266 228.177 206.84 233.748 198.332C262.341 154.67 262.457 100.262 234.048 56.8423C227.749 47.2143 210.42 29.3733 201.129 22.9513C185.936 12.4473 166.593 4.56829 148.217 1.39729C140.191 0.0112929 120.292 -0.466707 112.098 0.528293ZM146.598 101.364V157.864H127.598H108.598V101.364V44.8643H127.598H146.598V101.364ZM136.699 179.648C141.014 181.902 142.56 183.448 144.814 187.763C148.264 194.367 148.343 198.988 145.115 205.418C138.666 218.265 122.327 220.292 112.789 209.428C104.371 199.842 107.303 185.18 118.78 179.461C125.482 176.121 130.038 176.169 136.699 179.648Z'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, window.Convermax.config?.verifyFitmentUniversalTitle || 'Universal Fit'), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, window.Convermax.config?.verifyFitmentUniversalText || 'This product may require modification.'))) : null, this.template === 'unknown' ? _createElement('div', {
        'className': 'cm_vehicle-widget_label cm_verify-fitment cm_verify-fitment__unknown cmTemplate_unknown',
        'key': '23587'
    }, _createElement('div', { 'className': 'cm_verify-fitment_icon' }, this.fits === 'yes' || this.fits === 'universal' ? [_createElement('svg', {
            'width': '17',
            'height': '16',
            'viewBox': '0 0 17 16',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '237800'
        }, _createElement('path', {
            'd': 'M7.14299 1.19198C7.9192 0.51017 9.0808 0.510169 9.85701 1.19198C10.2816 1.56497 10.841 1.74673 11.4038 1.69457C12.4325 1.59921 13.3723 2.28199 13.5995 3.28983C13.7238 3.84116 14.0695 4.31703 14.5555 4.60561C15.4438 5.13314 15.8027 6.23789 15.3941 7.1868C15.1706 7.7059 15.1706 8.2941 15.3941 8.8132C15.8027 9.76211 15.4438 10.8669 14.5555 11.3944C14.0695 11.683 13.7238 12.1588 13.5995 12.7102C13.3723 13.718 12.4325 14.4008 11.4038 14.3054C10.841 14.2533 10.2816 14.435 9.85701 14.808C9.0808 15.4898 7.9192 15.4898 7.14299 14.808C6.71837 14.435 6.15895 14.2533 5.59619 14.3054C4.56747 14.4008 3.62771 13.718 3.4005 12.7102C3.27621 12.1588 2.93047 11.683 2.44453 11.3944C1.55623 10.8669 1.19727 9.76211 1.60585 8.8132C1.82936 8.2941 1.82936 7.7059 1.60585 7.1868C1.19727 6.23789 1.55623 5.13314 2.44453 4.60561C2.93047 4.31703 3.27621 3.84116 3.4005 3.28983C3.62771 2.28199 4.56747 1.59921 5.59619 1.69457C6.15895 1.74673 6.71837 1.56497 7.14299 1.19198Z',
            'fill': '#EA0029'
        }), _createElement('path', {
            'd': 'M7.01462 9.52793L5.34624 7.76271L5.23723 7.64737L5.12821 7.76271L4.53575 8.38956L4.43836 8.49259L4.53575 8.59563L6.90561 11.103L7.01462 11.2184L7.12364 11.103L12.2019 5.73001L12.2993 5.62698L12.2019 5.52394L11.6094 4.89709L11.5004 4.78175L11.3914 4.89709L7.01462 9.52793Z',
            'fill': 'white',
            'stroke': 'white',
            'strokeWidth': '0.3'
        }))] : null, this.fits === 'no' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-times',
            'height': '20px',
            'role': 'img',
            'viewBox': '0 0 22 22',
            'key': '253060'
        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))] : null, this.fits === 'unknown' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-questionmark',
            'xmlns': 'http://www.w3.org/2000/svg',
            'viewBox': '0 0 512 512',
            'key': '254960'
        }, _createElement('path', {
            'd': 'M160 164s1.44-33 33.54-59.46C212.6 88.83 235.49 84.28 256 84c18.73-.23 35.47 2.94 45.48 7.82C318.59 100.2 352 120.6 352 164c0 45.67-29.18 66.37-62.35 89.18S248 298.36 248 324',
            'fill': 'none',
            'stroke': '#000',
            'strokeLinecap': 'round',
            'strokeMiterlimit': '10',
            'strokeWidth': '60'
        }), _createElement('circle', {
            'cx': '248',
            'cy': '399.99',
            'r': '32',
            'fill': '#000'
        }))] : null, this.fits === 'aggressive' ? [_createElement('svg', {
            'className': 'cm_icon cm_icon-exclamation',
            'height': '20',
            'viewBox': '0 0 256 255',
            'fill': 'none',
            'xmlns': 'http://www.w3.org/2000/svg',
            'key': '260040'
        }, _createElement('path', {
            'fillRule': 'evenodd',
            'clipRule': 'evenodd',
            'd': 'M112.098 0.528293C105.702 1.30529 89.9175 5.21029 83.6615 7.56329C64.7805 14.6653 49.2545 25.1013 34.5985 40.5423C-0.248547 77.2563 -9.69655 131.035 10.4785 177.833C17.3025 193.66 23.8655 203.124 37.5985 216.939C51.1805 230.602 61.9924 238.186 77.0984 244.649C104.915 256.549 138.414 258.074 166.974 248.741C177.998 245.139 194.9 236.465 203.598 229.946C212.511 223.266 228.177 206.84 233.748 198.332C262.341 154.67 262.457 100.262 234.048 56.8423C227.749 47.2143 210.42 29.3733 201.129 22.9513C185.936 12.4473 166.593 4.56829 148.217 1.39729C140.191 0.0112929 120.292 -0.466707 112.098 0.528293ZM146.598 101.364V157.864H127.598H108.598V101.364V44.8643H127.598H146.598V101.364ZM136.699 179.648C141.014 181.902 142.56 183.448 144.814 187.763C148.264 194.367 148.343 198.988 145.115 205.418C138.666 218.265 122.327 220.292 112.789 209.428C104.371 199.842 107.303 185.18 118.78 179.461C125.482 176.121 130.038 176.169 136.699 179.648Z'
        }))] : null), _createElement('div', { 'className': 'cm_verify-fitment_fitment' }, _createElement('div', { 'className': 'cm_verify-fitment_title' }, 'No Fitment Data'), _createElement('div', { 'className': 'cm_verify-fitment_notes' }, 'This product doesn\'t have fitment application data. Please verify fitment manually.'))) : null);
}
        export const componentNames = ["cm:filterInput","cm:filterInput"]